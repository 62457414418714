body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    background-color: #f5f5f5;
}

strong {
    font-weight: 700;
}

div.DraftEditor-root {
    overflow: hidden;
    height: 500px;
    overflow-y: scroll;
}

.public-DraftEditor-content {
    height: 500px;
}

.public-DraftStyleDefault-unorderedListItem {
    list-style-type: none;
    display: flex;
    align-items: center;
}
.public-DraftStyleDefault-unorderedListItem:before {
    content: "■";
    transform: translateY(-1px);
    margin-right: 15px;
}

.sidenav li > a {
    font-weight: 400;
}

@page {
    size: 210mm 297mm;
    margin: 0;
}

@media print {
    .noprint {
        display: none;
    }
    .card {
        display: initial;
    }
    .col.s1,
    .col.s2,
    .col.s3,
    .col.s4,
    .col.s5,
    .col.s6,
    .col.s7,
    .col.s8,
    .col.s9,
    .col.s10,
    .col.s11,
    .col.s12 {
        width: 100% !important;
    }
}

.input-field input[type="text"]:focus {
    border-bottom: 1px solid #039be5;
    box-shadow: 0 1px 0 0 #039be5;
}

.input-field input[type="password"]:focus {
    border-bottom: 1px solid #039be5;
    box-shadow: 0 1px 0 0 #039be5;
}

.switch label input[type="checkbox"]:checked + .lever {
    background-color: #039be5;
}

.switch label input[type="checkbox"]:checked + .lever:after {
    background-color: #039be5;
}

.picker__date-display {
    background-color: #039be5;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
    background-color: #039be5;
}

.picker__day.picker__day--today {
    color: #039be5;
}

.picker__close,
.picker__today {
    color: #039be5;
}

.picker__weekday-display {
    background-color: #039be5;
}

input:not([type]):focus:not([readonly]),
input[type="text"]:focus:not([readonly]),
input[type="password"]:focus:not([readonly]),
input[type="email"]:focus:not([readonly]),
input[type="url"]:focus:not([readonly]),
input[type="time"]:focus:not([readonly]),
input[type="date"]:focus:not([readonly]),
input[type="datetime"]:focus:not([readonly]),
input[type="datetime-local"]:focus:not([readonly]),
input[type="tel"]:focus:not([readonly]),
input[type="number"]:focus:not([readonly]),
input[type="search"]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-bottom: 1px solid #039be5;
    box-shadow: 0 1px 0 0 #039be5;
}

.autocomplete-content {
    position: absolute;
    width: 88%;
}

input[type="range"]::-webkit-slider-thumb {
    background-color: #039be5;
}
input[type="range"]::-moz-range-thumb {
    background-color: #039be5;
}
input[type="range"]::-ms-thumb {
    background-color: #039be5;
}

input[type="range"] + .thumb {
    background-color: #039be5;
}

.chips.focus {
    border-bottom: 1px solid #039be5;
    box-shadow: 0 1px 0 0 #039be5;
}

.chips .chip.selected {
    background-color: #039be5;
    color: #fff;
}

.dropdown {
    position: relative;
    padding: 0;
    border: 0;
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #9e9e9e;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
}
.dropdown > input {
    display: none;
    position: fixed;
    z-index: -2;
}
.dropdown > label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
}
.drop-ttl {
    font-weight: 400;
    padding: 5px 20px;
}
.dropdown ul {
    width: 100%;
    font-weight: 400;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    text-align: center;
    list-style-type: none;
    background: #fff;
    color: #039be5;
    box-shadow: 0px 8px 17px 0px rgba(0, 0, 0, 0.2), 0px 6px 20px 0px rgba(0, 0, 0, 0.19);
    border-radius: 5px;
}
.dropdown ul > li {
    padding: 5px 20px;
}
.dropdown ul > li > a {
    color: inherit;
    outline: 0;
    text-decoration: none;
    cursor: pointer;
}
.dropdown ul > li:hover {
    background: #dedede;
}
input[type="checkbox"]:checked ~ label > ul {
    display: none;
}

input:not([type]):focus:not([readonly]) + label,
input[type="text"]:focus:not([readonly]) + label,
input[type="password"]:focus:not([readonly]) + label,
input[type="email"]:focus:not([readonly]) + label,
input[type="url"]:focus:not([readonly]) + label,
input[type="time"]:focus:not([readonly]) + label,
input[type="date"]:focus:not([readonly]) + label,
input[type="datetime"]:focus:not([readonly]) + label,
input[type="datetime-local"]:focus:not([readonly]) + label,
input[type="tel"]:focus:not([readonly]) + label,
input[type="number"]:focus:not([readonly]) + label,
input[type="search"]:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
    color: #039be5;
}

.row .col {
    padding-bottom: 10px;
}

.card .card-action {
    border-top: 0px;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.visible {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s linear;
}

.input-field .postfix ~ input {
    width: 80%;
    width: calc(100% - 3rem);
    margin-left: 0px;
    margin-right: 3rem !important;
}
.input-field .postfix {
    position: absolute;
    right: 0px;
    font-size: 2rem;
    -webkit-transition: color 0.2s;
    transition: color 0.2s;
}
.input-field .postfix.active {
    color: #039be5;
}

.tiny-width {
    width: 5%;
}

.small-width {
    width: 8%;
}

.medium-width {
    width: 12%;
}

.simple-list-space {
    margin-bottom: 180px;
}

.scroll-vertical {
    overflow-y: scroll;
    height: calc(100vh - 100px);
}

.scroll-vertical-full {
    overflow-y: scroll;
    height: calc(100vh - 10px);
}

.fixed-btn {
    bottom: 50px;
    right: 50px;
}

.padding-left {
    padding-left: 20px;
}

.dropdown-content li > a,
.dropdown-content li > span {
    color: black;
}

.select-dropdown {
    max-height: 500px;
}

.collapsible-table-wrapper li > div:first-child {
    border-bottom: none !important;
}

.collapsible-table-wrapper li:nth-child(even) > div:first-child {
    background-color: rgba(242, 242, 242, 0.5) !important;
}
